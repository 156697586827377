import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebook,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import Privacy from "../components/privacy"

const Footer = () => {
  return (
    <footer className="pt-3 pb-3 bg-dark text-light text-center">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 d-flex align-items-center">
            <ul className="list-inline social">
              <li className="list-inline-item">Connect with us on</li>
              <li className="list-inline-item">
                <a href="https://twitter.com/pocd">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.facebook.com/pocdiagnostics">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.youtube.com/c/PointofCareDiagnosticsArtarmon"
                  aria-label="You Tube"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.linkedin.com/company/point-of-care-diagnostics"
                  aria-label="LinkedIn"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 d-flex align-items-center justify-content-end">
            <p>
              Created by
              {` `}
              <a href="https://fluidic.agency">fluidic</a> for
              {` `}
              <a href="http://www.pocd.com.au">POCD</a>
            </p>
          </div>
        </div>
        <div className="row fineprint pt-3">
          <div className="col-12 text-center">
            <Privacy />
            <p>Copyright © 2018. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
