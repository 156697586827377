import React from "react"
import Modal from "react-bootstrap/Modal"

const Privacy = () => {
  const [show, setShow] = React.useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <p>
        <a href="#" onClick={handleShow}>
          Terms, Privacy &amp; Disclaimer
        </a>
      </p>
      <Modal show={show} onHide={handleClose} dialogClassName="privacy-modal">
        <Modal.Header closeButton>
          <Modal.Title>The Legal Stuff&hellip;</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            The following sections provide information on our
            {` `}
            <a href="#terms">Terms &amp; Conditions</a>,{` `}
            <a href="#privacy">Privacy Policy</a> and
            {` `}
            <a href="#disclaimer">Information Only Disclaimer</a>.
          </p>
          <p id="terms" className="h3">
            Terms &amp; Conditions
          </p>
          <p>
            Welcome to our website. This website with URL address
            https://www.unifinepentips.com.au is owned and operated by Point of
            Care Diagnostics Australia Pty Ltd (ABN 93 067 939 824). Should you
            continue to use this website, you are agreeing to comply with and be
            bound by the following terms and conditions of use, which together
            with our privacy policy govern Point of Care Diagnostics’s
            relationship with you in connection with this website. Should you
            not agree with any of these terms and conditions, please do not use
            our website.
          </p>
          <p>
            The term ‘Point of Care Diagnostics’ or ‘us’ or ‘our’ or ‘we’ refers
            to Point of Care Diagnostics, the owner of the website, whose
            registered office is 93067939824, New South Wales. The term ‘you’ or
            ‘your’ refers to the website user. Your use of this website is
            subject to the following terms and conditions:
          </p>
          <p>
            The content of this website is for your general information and use
            only. It is subject to change without prior notice.
          </p>
          <ol>
            <li>
              Information on this website is general in nature and should not be
              relied on as medical or professional advice as it does not take
              into consideration any user’s personal situation or needs. You
              should seek medical advice before deciding whether to purchase a
              product. This website uses cookies to monitor browsing
              preferences.
            </li>
            <li>
              We also use Google Analytics and other third-party analytics
              providers to help measure how users interact with our website
              content. These cookies “remember” what our users have done on
              previous pages and how they’ve interacted with the website. For
              more information on Google Analytics, visit Google’s information
              page. For instructions on how opt out of Google Analytics.
            </li>
            <li>
              Targeting cookies are used on our website to tailor marketing to
              you and your interests and provide you with a more personalised
              service in the future. These cookies remember that you visited our
              website and we may share this information with third-parties, such
              as advertisers. Although these cookies can track your visits to
              our website and other sites, they typically cannot personally
              identify you.
            </li>
            <li>
              Social plug-in tracking cookies are used by many social networks
              that have “social plug-in modules”. We integrate these modules
              into our platform to provide services than can be considered as
              “explicitly requested” by our users. Your consent, however, is
              required because some third-party social plug-in tracking cookies
              are used for things like behavioural advertising, analytics,
              and/or market research.
            </li>
            <li>
              Neither we nor any third parties provide any warranty or guarantee
              as to the performance, accuracy, timeliness, completeness or
              suitability of the information and materials found or offered on
              this website for any purpose. You hereby acknowledge that such
              information and materials may contain mistakes, inaccuracies or
              errors and we expressly exclude any liability for such to the
              extent permissible by law.
            </li>
          </ol>
          <p id="privacy" className="h3">
            Privacy Policy
          </p>
          <p>
            <b>
              Point of Care Diagnostics Australia Pty Ltd ABN 93 067 939 824
            </b>
          </p>
          <p>
            This Privacy Policy sets out our commitment to protecting the
            privacy of your personal information that we collect through this
            website https://www.unifinepentips.com.au (Site) or directly from
            you.
          </p>
          <p>
            Please read this Privacy Policy carefully. Please contact us if you
            have any questions.
          </p>
          <p>
            You providing us with personal information indicates that you have
            had sufficient opportunity to access this Privacy Policy and that
            you have read and accepted it.
          </p>
          <p>
            If you do not wish to provide personal information to us, then you
            do not have to do so, however it may affect your use of this Site or
            any products and services offered on it.
          </p>
          <p>
            <b>1. Type of personal information collected</b>
          </p>
          <p>
            Personal Information: The type of personal information we collect
            may include is set out on our website.
          </p>
          <p>
            If we receive your personal information from third parties, we will
            protect it as set out in this Privacy Policy.
          </p>
          <p>
            <b>2. Collection and use of personal information</b>
          </p>
          <p>
            We collect and use the personal information for purposes including
            to contact and communicate with you, for internal record keeping and
            for marketing.
          </p>
          <p>
            <b>3. Disclosure of personal information</b>
          </p>
          <p>
            We may disclose personal information for purposes including to
            provide our products and services to you, and as required by law.
          </p>
          <p>
            Where we disclose your personal information to third parties for
            these purposes, we will request that the third party follow this
            Privacy Policy regarding handling of your personal information.
          </p>
          <p>
            <b>4. Access to and correction of personal information</b>
          </p>
          <p>
            Access: You may request details of personal information that we hold
            about you, in certain circumstances set out in the Privacy Act 1988
            (Cth). An administrative fee may be payable for the provision of
            information. We may refuse to provide you with information that we
            hold about you, in certain circumstances set out in the Privacy Act.
          </p>
          <p>
            Correction: If you believe that any information we hold on you is
            inaccurate, out of date, incomplete, irrelevant or misleading,
            please contact us by email. We rely in part upon customers advising
            us when their personal information changes. We will respond to any
            request within a reasonable time. We will endeavor to promptly
            correct any information found to be inaccurate, incomplete or out of
            date.
          </p>
          <p>
            <b>5. Complaints about breach</b>
          </p>
          <p>
            If you believe that we have breached the Australian Privacy
            Principles and wish to make a complaint about that breach, please
            contact us on the email address below.
          </p>
          <p>
            <b>6. Unsubscribe</b>
          </p>
          <p>
            To unsubscribe from our email database, or opt out of
            communications, please contact us at the details below.
          </p>
          <p>
            <b>7. Storage and Security</b>
          </p>
          <p>
            We are committed to ensuring that the information you provide is
            secure.
          </p>
          <p>For any questions or notice, please contact us at:</p>
          <p>Point of Care Diagnostics Australia Pty Ltd ABN: 93 067 939 824</p>
          <p>
            Email:
            <a href="mailto:admin@pocd.com.au">
              admin@pocd.com.au
            </a>
          </p>
          <p id="disclaimer" className="h3">
            Disclaimer
          </p>
          <p>
            The content on this website is for general information only. It
            should not be relied on as medical or personal advice as it does not
            take into consideration your particular personal medical situation
            or health needs.
          </p>
          <p>
            You should seek advice before deciding whether any of these products
            are right for you.
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Privacy
