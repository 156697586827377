import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logo from "../images/up-logo-white.png"

const Header = ({ siteTitle }) => (
  <header>
    <div className="container pt-1 pb-1">
      <div className="row">
        <div className="col-md-6 d-flex flex-column align-items-center align-items-md-start">
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
            className="mb-auto"
          >
            <img src={logo} alt={siteTitle} />
          </Link>
          <span style={{ fontWeight: `100`, fontSize: `18px`, color: `white` }}>
            <em>A quality pen needle for every patient</em>
          </span>
        </div>
        <div className="col-md-6 d-none d-md-flex flex-column align-items-end">
            <a href="#samples" className="btn btn-sm btn-ghost scroll">
              Order Samples
            </a>
            <a className="phone" href="tel:1800640075">
              1800 640 075
            </a>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
